// @flow

import React from "react";
import Helmet from "react-helmet";
import styled from "@emotion/styled";

import useSiteMetadata from "../hooks/use-sitemetadata";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const LayoutStyled = styled("div")`
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  line-height: 1.4;

  *:focus {
    box-shadow: none !important;
    outline: 2px dashed #00000099;
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 960px;
    }
  }
`;

const ContentStyled = styled("div")`
  // margin-top: 300px;

  a {
    color: black;
  }
  p {
    margin-bottom: 1.5em;
    white-space: pre-wrap;
  }
  h3 {
    font-size: 20px;
    font-weight: bold;
  }
`;

type Props = {
  children: Node,
  title: string
};

function Layout(props: Props) {
  const { children, title = "" } = props;
  const metadata = useSiteMetadata();

  return (
    <>
      <Helmet>
        <html lang="pl" />
        <title>
          {title} – {metadata.title}
        </title>
        <meta name="description" content={metadata.description} />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <LayoutStyled className="d-flex flex-column">
        <Navbar />
        <ContentStyled className="p-3 p-sm-0">{children}</ContentStyled>
        <Footer />
      </LayoutStyled>
    </>
  );
}

export default Layout;
