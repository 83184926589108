// @flow

import React from "react";
import { graphql } from "gatsby";

import Group from "../components/Group";
import Layout from "../layouts/groupLayout";

// $FlowFixMe
export const query = graphql`
  query($directory: String!) {
    allMdx(filter: { fileAbsolutePath: { regex: $directory } }) {
      nodes {
        fileAbsolutePath
        frontmatter {
          name
          image {
            sharp: childImageSharp {
              fluid(maxWidth: 700, maxHeight: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          audio {
            publicURL
          }
          images {
            title
            description
            date
            place
            author
            src {
              childImageSharp {
                preview: fluid(
                  maxWidth: 300
                  maxHeight: 200
                  cropFocus: ENTROPY
                ) {
                  ...GatsbyImageSharpFluid
                }
                big: fluid(maxWidth: 3000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          songs {
            title
            sheet {
              sharp: childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            audio {
              publicURL
            }
            sheetFile
            textFile
            text
          }
        }
        body
      }
    }
  }
`;

function getContentByFile(
  nodes: Array<Object>,
  fileName: string,
  container: string = "body"
) {
  const node = nodes.find(node => node.fileAbsolutePath.includes(fileName));
  if (node) {
    return node[container] || {};
  }
  return null;
}

type Props = {
  data: Object
};

function GroupTemplate(props: Props) {
  const { data = {} } = props;

  const { nodes = {} } = data.allMdx;
  const groupProps = {
    description: getContentByFile(nodes, "info.mdx", "body"),
    info: getContentByFile(nodes, "info.mdx", "frontmatter"),
    list: getContentByFile(nodes, "list.mdx", "body")
    // gallery: getContentByFile(nodes, "gallery.mdx", "frontmatter"),
    // songs: getContentByFile(nodes, "songs.mdx", "frontmatter")
  };

  return (
    <Layout title={groupProps.info.name}>
      <Group {...groupProps} />
    </Layout>
  );
}

export default GroupTemplate;
