// @flow

import React, { useState } from "react";
import Image from "gatsby-image";
import styled from "@emotion/styled";

import TextIcon from "../assets/text.svg";

import AudioPlayer from "./AudioPlayer";
import ActionButton from "./ActionButton";
import DownloadButton from "./DownloadButton";

const SongBackgroundStyled = styled("div")`
  background: #f7f7f7;
`;

const SongTextStyled = styled("div")`
  white-space: pre-wrap;
`;

type Props = {
  song: {
    title: string,
    audio: { publicURL: string },
    sheet: { sharp: { fluid: string } },
    sheetFile: string,
    textFile: string,
    text: string
  }
};

function Song(props: Props) {
  const { song } = props;
  const { title, sheet, sheetFile, audio, textFile, text } = song;

  const [isTextShown, setTextShown] = useState(false);

  return (
    <figure>
      <SongBackgroundStyled className="w-100 p-3 p-lg-5 mt-5">
        {sheet && (
          <div className="p-0 container">
            <Image
              fluid={sheet.sharp.fluid}
              alt={`Zapis nutowy pieśni ${title}`}
            />
          </div>
        )}
      </SongBackgroundStyled>
      <div className="p-0 container">
        <div className="row mt-5 mb-5">
          <AudioPlayer
            className="col-12 col-lg-4"
            title={
              <span>
                Odtwórz pieśń <span className="sr-only">{title}</span>
              </span>
            }
            src={audio.publicURL}
          />
          <div className="col-12 col-lg-8 d-flex flex-column">
            <div className="d-flex flex-row justify-content-end mb-2">
              {sheet && (
                <DownloadButton
                  className="mr-2"
                  file={sheet.sharp.fluid.src}
                  text={
                    <span>
                      Pobierz nuty{" "}
                      <span className="sr-only">pieśni {title}</span>
                    </span>
                  }
                />
              )}
              {sheetFile && (
                <DownloadButton
                  className="mr-2"
                  file={sheetFile}
                  text={
                    <span>
                      Pobierz MuseScore{" "}
                      <span className="sr-only">pieśni {title}</span>
                    </span>
                  }
                />
              )}
            </div>
            <div className="d-flex flex-row justify-content-end">
              <ActionButton
                className="mr-2"
                icon={<TextIcon className="mr-3" />}
                text={
                  <span>
                    {isTextShown ? "Ukryj" : "Zobacz"} tekst pieśni{" "}
                    <span className="sr-only">{title}</span>
                  </span>
                }
                onClick={() => setTextShown(!isTextShown)}
              />
              {textFile && (
                <DownloadButton
                  className="mr-2"
                  file={textFile}
                  text={
                    <span>
                      Pobierz tekst{" "}
                      <span className="sr-only">pieśni {title}</span>
                    </span>
                  }
                />
              )}
            </div>
          </div>
        </div>
        {isTextShown && (
          <div className="d-flex justify-content-center">
            <SongTextStyled>{text}</SongTextStyled>
          </div>
        )}
      </div>
    </figure>
  );
}

export default Song;
