// @flow

import React from "react";
import Image from "gatsby-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import styled from "@emotion/styled";

import AudioPlayer from "./AudioPlayer";
import Header from "./Header";
import GroupGallery from "./GroupGallery";
import Song from "./Song";

const TitleStyled = styled("h1")`
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 35px;
`;

const ListStyled = styled("div")`
  font-size: 16px;
  line-height: 29px;

  ul {
    padding: 0;
    list-style-type: none;
  }
`;

type Props = {
  description: MDXRenderer,
  info: {
    name: string,
    image: { sharp: { fluid: string } },
    audio: { publicURL: string },
    images: Array<{
      author: string,
      date: string,
      description: string,
      place: string,
      src: { childImageSharp: { big: Object, preview: Object } },
      title: string
    }>,
    songs: Array<SongType>
  },
  list: MDXRenderer
};

function Group(props: Props) {
  const { description, info, list } = props;

  const groupGallery = info.images ? (
    <GroupGallery className="mt-5" images={info.images} />
  ) : null;

  const groupSongs = info.songs
    ? info.songs.map(song => <Song key={song.audio.publicURL} song={song} />)
    : null;

  return (
    <>
      <div className="p-0 container">
        <div className="row">
          <main className="col-12 col-lg-9">
            <header>
              <TitleStyled>{info.name}</TitleStyled>
            </header>
            <Image
              fluid={info.image && info.image.sharp.fluid}
              alt={info.name}
            />
            <AudioPlayer
              className="mt-5 mb-5"
              title="Odtwórz nagranie artykułu"
              src={info.audio && info.audio.publicURL}
            />
            <MDXRenderer>{description}</MDXRenderer>
          </main>
          <aside className="col-12 col-lg-3 mt-5 mt-lg-0">
            <Header>Lista członkiń i członków zespołu</Header>
            <ListStyled>
              <MDXRenderer>{list}</MDXRenderer>
            </ListStyled>
          </aside>
        </div>
      </div>
      <section className="p-0 mt-5 container" aria-labelledby="galeria">
        <Header id="galeria">Galeria</Header>
        {groupGallery}
      </section>
      <section className="p-0 mt-5" aria-labelledby="piesni">
        <div className="p-0 container">
          <Header id="piesni">Pieśni</Header>
        </div>
        {groupSongs}
      </section>
    </>
  );
}

export default Group;
