// @flow

import React, { useState } from "react";
import Image from "gatsby-image";
import styled from "@emotion/styled";

import LeftArrowIcon from "../assets/arrow-left.svg";
import RightArrowIcon from "../assets/arrow-right.svg";
import DownloadButton from "./DownloadButton";
import ActionButton from "./ActionButton";

const ActiveImageStyled = styled("div")`
  .gatsby-image-wrapper {
    margin-bottom: 1.5rem;
    max-height: 650px;
  }
  .description {
    font-size: 16px;
    font-weight: bold;
  }
`;

type Props = {
  className: string,
  images: Array<{
    author: string,
    date: string,
    description: string,
    place: string,
    src: { childImageSharp: { big: Object, preview: Object } },
    title: string
  }>
};

function GroupGallery(props: Props) {
  const { className = "", images = [] } = props;
  const [activeImage, setActiveImage] = useState(
    images.length ? images[0] : {}
  );

  if (!images.length) {
    return null;
  }

  const nextImage = () => {
    const currentImageIndex = images.indexOf(activeImage);
    const newIndex =
      currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1;
    setActiveImage(images[newIndex]);
  };

  const prevImage = () => {
    const currentImageIndex = images.indexOf(activeImage);
    const newIndex =
      currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1;
    setActiveImage(images[newIndex]);
  };

  const list = images.map(image => (
    <li className="pb-4 col-4 col-md-4 col-lg-4" key={image.description}>
      <a
        href="#galeria"
        className="w-100 p-0 btn btn-link"
        type="button"
        onClick={() => setActiveImage(image)}
      >
        <figure className="m-0">
          <Image
            fluid={image.src.childImageSharp.preview}
            alt={`${image.description}. Powiększ zdjęcie.`}
            imgStyle={{ objectFit: "contain" }}
          />
        </figure>
      </a>
    </li>
  ));

  const active = (
    <ActiveImageStyled key={activeImage.description} className="col-12 mb-5">
      <div className="d-flex align-items-center w-100">
        <ActionButton
          className="mr-1"
          icon={<LeftArrowIcon />}
          text={<span className="sr-only">Pokaż poprzednie zdjęcie</span>}
          onClick={prevImage}
        />
        <figure className="flex-grow-1">
          <Image
            fluid={activeImage.src.childImageSharp.big}
            alt={activeImage.description}
            imgStyle={{ objectFit: "contain" }}
          />
          <figcaption className="description">{activeImage.title}</figcaption>
        </figure>
        <ActionButton
          className="ml-1"
          icon={<RightArrowIcon />}
          text={<span className="sr-only">Pokaż następne zdjęcie</span>}
          onClick={nextImage}
        />
      </div>
      <div className="d-flex flex-column flex-sm-row">
        <ul className="d-flex flex-column flex-grow-1 list-unstyled description">
          {activeImage.date && <li>Data: {activeImage.date}</li>}
          {activeImage.place && <li>Miejsce: {activeImage.place}</li>}
          {activeImage.author && (
            <li>Autor(ka) zdjęcia: {activeImage.author}</li>
          )}
        </ul>
        <div>
          <DownloadButton
            file={activeImage.src.childImageSharp.big.src}
            text="Pobierz zdjęcie"
          />
        </div>
      </div>
    </ActiveImageStyled>
  );

  return (
    <div className={`p-0 container ${className}`}>
      <div className="row">{active}</div>
      <ul className="row list-unstyled">{list}</ul>
    </div>
  );
}

export default GroupGallery;
