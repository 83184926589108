// @flow

import React from "react";
import styled from "@emotion/styled";
import { useAudio } from "react-use";
import ReactSlider from "react-slider";

import PlayIcon from "../assets/play-btn.svg";
import PauseIcon from "../assets/pause-btn.svg";

// $FlowFixMe
const AudioPlayerStyled = styled("div")`
  width: 230px;

  .slider {
    width: 100%;
    height: 18px;
    margin-bottom: 20px;

    .track {
      position: relative;
      background: black;
      top: 6px;
      height: 3px;
    }

    .track-0 {
      background: #fddb5d;
    }

    .thumb {
      top: -1px;
      width: 18px;
      height: 18px;
      background: #fddb5d;
      border-radius: 50%;
      cursor: pointer;
      border: 3px solid black;
      box-sizing: border-box;
    }
  }
`;

const TitleStyled = styled("h2")`
  font-size: 16px;
  line-height: 29px;
  font-weight: bold;
  padding-bottom: 10px;
  margin-bottom: 0;
`;

const ButtonStyled = styled("button")`
  border-radius: 50%;
  width: 48px;
  height: 48px;

  &:focus {
    border-radius: 50%;
  }

  &:hover {
    background: #fddb5d;
  }
`;

type Props = {
  className: string,
  src: string,
  title: string
};

function AudioPlayer(props: Props) {
  const { className, src, title } = props;

  if (!src) {
    return null;
  }

  const [audio, state, controls] = useAudio({ src });

  return (
    <AudioPlayerStyled className={className}>
      <TitleStyled>{title}</TitleStyled>
      {audio}
      <ReactSlider
        ariaLabel="Postęp odtwarzania"
        ariaValuetext={state => `Odtworzono ${Math.round(state.value)} procent`}
        onAfterChange={value => controls.seek(value)}
        max={state.duration}
        value={state.time || 0}
      />
      <ButtonStyled
        aria-label="Odtwórz"
        className="btn btn-link p-1 mr-3"
        type="button"
        onClick={controls.play}
      >
        <PlayIcon />
      </ButtonStyled>
      <ButtonStyled
        aria-label="Zatrzymaj"
        className="btn btn-link p-1"
        type="button"
        onClick={controls.pause}
      >
        <PauseIcon />
      </ButtonStyled>
    </AudioPlayerStyled>
  );
}

export default AudioPlayer;
