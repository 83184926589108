// @flow
import React from "react";
import styled from "@emotion/styled";

import DownloadIcon from "../assets/download.svg";

// $FlowFixMe
const ButtonStyled = styled("a")`
  font-size: 16px;
  font-weight: bold;
  color: black;

  &:hover {
    color: black;
    background: #fddb5d;
  }

  svg {
    height: 34px;
  }
`;

type Props = {
  className: string,
  file: string,
  icon: Element,
  text: string
};

function DownloadButton(props: Props) {
  const { className = "", file, icon, text } = props;

  return (
    <ButtonStyled
      className={`d-flex align-items-end btn btn-link ${className}`}
      href={file}
      role="button"
      download
    >
      <span className="mr-3">
        <DownloadIcon />
      </span>
      <span>{text}</span>
    </ButtonStyled>
  );
}

export default DownloadButton;
